import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils/index.js';
import { BlockContainer } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, audio1 = 'Halina Regulska', audio2 = 'Kajetan Morawski' }) => (
  <BlockContainer height="1750px">
    <BackgroundPhoto
      fixed={query.file.childImageSharp.fixed}
      scale
      alt="slide-photo"
    >
      <TextAnimation
        containerStyle={{ height: '30%', marginTop: '7rem' }}
        body={query.mdx.body}
      />
      <AudioPlayer
        audio={[
          `${mediaServer}/webm/${getLangFromPath().toLowerCase()}/313regulska.webm`,
          `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/313regulska.mp4`,
        ]}
        audioImage={query.audioImageA.childImageSharp.fluid}
        audioDescription={audio1}
        size={2}
        style={{ margin: '10rem 0' }}
      />
      <AudioPlayer
        audio={[
          `${mediaServer}/webm/${getLangFromPath().toLowerCase()}/313morawski.webm`,
          `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/313morawski.mp4`,
        ]}
        audioImage={query.audioImageB.childImageSharp.fluid}
        audioDescription={audio2}
        size={2}
        style={{ margin: '10rem 0' }}
      />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
