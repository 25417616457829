import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import { SlideLarge, ResponsiveImage } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    main: file(relativePath: { eq: "chapter_3/sub_1/2-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  map: file(relativePath: { eq: "chapter_3/sub_1/3-1-2-map.jpeg"}) {
    childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
},
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.main.childImageSharp.fluid }}
        />
        <Mordo
          flexi
          scene={1}
          move={{ x: -10, y: -20 }}
        >
          <div css={{
            alignSelf: 'flex-start', width: '100%', margin: '8% auto 0',
          }}
          >
            <LeftColContent smallerText={window.innerWidth < 1700} body={query.mdx.body} dark style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} />
          </div>
          <div css={{
            alignSelf: 'flex-start', width: '100%', margin: '8% 2% 0 auto',
          }}
          >
            <ResponsiveImage fluid={assetQuery.map.childImageSharp.fluid} />
          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
