import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';

import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils/index.js';
import { SlideLarge } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const language = getLangFromPath();

  const assetQuery = useStaticQuery(graphql`
  query {
    main: file(relativePath: { eq: "chapter_3/sub_1/3-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  }
  map: file(relativePath: { eq: "chapter_3/sub_1/3-1-2-map.jpeg"}) {
    childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    modal1: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-modal-1.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
      }
    }
    modal2: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-modal-2.jpg"}) {
      childImageSharp {
        fixed(width: 506, height: 762)  {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
        }
      }
    modal3: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-modal-3.jpg"}) {
      childImageSharp {
        fixed(width: 506, height: 762)  {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
        }
      }
      audio1: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-audio__A.png"}) {
        childImageSharp {
            fluid(maxWidth: 275) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
    },
    audio2: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-audio__B.png"}) {
        childImageSharp {
            fluid(maxWidth: 275) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
    },
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.main.childImageSharp.fluid }}
        />
        <Mordo
          flexi={{ alignItems: 'center' }}
          scene={1}
          move={{ x: -10, y: -20 }}
        >
          <div css={{
            margin: '25% auto 0',
          }}
          >
            <LeftColContent
              body={query.leftColumn.body}
              images={[assetQuery.modal1.childImageSharp.fixed, assetQuery.modal2.childImageSharp.fixed, assetQuery.modal3.childImageSharp.fixed]}
              style={{ display: 'flex', flexDirection: 'row' }}
            />
          </div>
          <div css={{
            display: 'flex', margin: '12% auto 0',
          }}
          >
            <AudioPlayer audio={[`${mediaServer}/webm/${language.toLowerCase()}/313regulska.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/313regulska.mp4`]} audioImage={assetQuery.audio1.childImageSharp.fluid} size={1} audioDescription={query.audio1.excerpt} />
            <AudioPlayer audio={[`${mediaServer}/webm/${language.toLowerCase()}/313morawski.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/313morawski.mp4`]} audioImage={assetQuery.audio2.childImageSharp.fluid} size={1} audioDescription={query.audio2.excerpt} />

          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
