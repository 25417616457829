import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import { ImgFull, BlockContainer } from '../../_styles';

const Slide = ({ query }) => (
  <BlockContainer height="1300px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} scale alt="slide-photo">
      <TextAnimation containerStyle={{ height: '75%', marginTop: '3rem', marginBottom: '2rem' }} dark body={query.mdx.body} />
      <ImgFull fixed={query.image.childImageSharp.fixed} />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
