import React from 'react';
import BackgroundAnimation from 'src/components/mobile/backgroundAnimation/backgroundAnimation';
import video from 'src/assets/videos/chapter_3/sub_1/3-1-1-bg__mobile.webm';
import videoMp4 from 'src/assets/videos/chapter_3/sub_1/3-1-1-bg__mobile.mp4';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import Logo from 'src/assets/images/chapter_3/sub_1/logo_niepodlegla.svg';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, title }) => (
  <BlockContainer height="650px">
    <BackgroundAnimation src={video} mp4={videoMp4} responsive oneTime>
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <img src={Logo} alt="trademark" style={{ width: '60%', marginLeft: '20%', marginTop: '10rem' }} />
      <TextAnimation body={query.mdx.body} containerStyle={{ height: '30%' }} />
    </BackgroundAnimation>
  </BlockContainer>
);

export default Slide;
