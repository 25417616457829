import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_3/sub_1/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-3-1-3__mobile"}}) {
      body
    }
    file(relativePath: { eq: "chapter_3/sub_1/mobile/3-1-3-bg__mobile.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    audioImageA: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-audio__A.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
    audioImageB: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-audio__B.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
