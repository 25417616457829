import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import { useStaticQuery, graphql } from 'gatsby';
import Video from 'videos/chapter_3/sub_1/3-1-1-bg.webm';
import VideoMP4 from 'videos/chapter_3/sub_1/3-1-1-bg.mp4';
import logo from 'src/assets/images/chapter_3/sub_1/logo_niepodlegla.svg';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    scenelayer1: file(relativePath: { eq: "chapter_3/sub_1/1-bg.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'transparent' }}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive noloop />
        </Mordo>

        <Mordo
          scenelayer={{ size: 'cover' }}
          scene={1}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={0.75}
        />
        <Mordo
          scenelayer={{ size: 'cover' }}
          scene={2}
          move={{ x: -10, y: -20 }}
          background={{ image: logo }}
        />
        <Mordo

          flexi
          scene={3}
          move={{ x: -10, y: -20 }}
        >
          <div css={{
            alignSelf: 'center', width: '100%', margin: '15% auto 0',
          }}
          >
            <div style={{
              background: `url(${logo})`,
              height: '18rem',
              margin: '0 auto 30px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            />
            <LeftColContent body={query.mdx.body} largeHeader />
          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
